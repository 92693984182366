.btn-container[data-v-1badd3ac] {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.btn-container .el-button[data-v-1badd3ac] {
  width: 70px;
  margin: 0 !important;
}
.user-dialog[data-v-1badd3ac] .el-dialog__body {
  padding: 0 20px !important;
}
.common_tree_handle[data-v-1badd3ac] {
  border-right: none !important;
  border-left: 1px solid #cccccc;
}
.assessment-content[data-v-1badd3ac] {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #cccccc;
}
.assessment-content .assessment-avatar[data-v-1badd3ac] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 30px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.assessment-content .assessment-avatar span[data-v-1badd3ac] {
  padding-top: 10px;
  text-align: center;
}
.assessment-content .assessment-item .assessment-rate .el-rate[data-v-1badd3ac] {
  padding-right: 30px;
}
.right-container[data-v-1badd3ac] {
  padding-left: 10px;
  position: relative;
}
.rightBtn[data-v-1badd3ac] {
  position: absolute;
  left: -6px;
  top: 50%;
  z-index: 99;
}
.smallContainer[data-v-1badd3ac] {
  height: 85vh;
  overflow: auto;
}